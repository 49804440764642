import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { BYPASS_MSAL } from './authguard/auth-interceptor';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  refreshAccessToken(): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/settings`, {
        context: new HttpContext().set(BYPASS_MSAL, true),
      })
      .pipe(
        tap((data: any) => {
          localStorage.setItem('token', data.data.token);
        })
      );
  }

  getJobseekerImg(img: any) {
    var config = this.getConfig();
    return this.baseUrl(img, config.directory.jobseeker);
  }

  getCOBImg(img: any) {
    var config = this.getConfig();
    return this.baseUrl(img, config.directory.cob);
  }
  getEmployerImg(img: any) {
    var config = this.getConfig();
    return this.baseUrl(img, config.directory.employeer);
  }

  private getConfig(): any {
    return JSON.parse(localStorage.getItem('config') || '');
  }

  private baseUrl(img: any, type: any): any {
    var config = this.getConfig();
    return `${config.baseUrl}/${type}/${img}`;
  }
}
