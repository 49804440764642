export const AppConstants = {
  // Validation Messages
  FILE_SIZE_EXCEEDED: 'File size should be under 2MB.',
  IMAGE_ASPECT_RATIO_INVALID: 'Image must be (1:1 aspect ratio).',

  // Success Messages
  PROFILE_PICTURE_UPDATED: 'Uploaded successfully',
  USER_IMAGE_UPDATED: 'Uploaded successfully',
  UPDATE_SUCCESS: 'Updated successfully',
  UPDATE_FAILED: 'Error occured',
  REMOVAL_FAILED: 'Failed to remove user profile',
  REACTIVATED: 'Your account has been reactivated',
  PROFILE_DISABLED:
    '<strong>Profile disabled successfully</strong><br><span style="font-size: small;">Your profile has been disabled successfully! We hope to see you soon!!</span>',
  PROFILE_REMOVED:
    '<strong>Profile removed successfully</strong><br><span style="font-size: small;">Your profile has been removed successfully! We hope to see you soon!!</span>',
  APPROVALS_SUCCESS_MSG: 'Approved successfully',
  DECLINE_SUCCESS_MSG: 'Declined successfully',
  DISABLE_SUCCESS_MSG: 'Disabled successfully',
  ENABLE_SUCCESS_MSG: 'Enabled successfully',
  CREATED_SUCCESS_MSG: 'Created successfully',
  UPDATED_SUCCESS_MSG: 'Updated successfully',
  VALIDATION_ERROR_MSG: 'Validation Failed',
  FAILED_MSG: 'Error while creation',
  FAILED_UPDATE_MSG: 'Error while updation',
  JOB_APPLY_SUCCESS_MSG:
    '<strong>Application Submitted Successfully</strong><br><span style="font-size: small;">Your job application has been submitted. You can track its status in your application history.</span>',
    JOB_APPLICATION_REMOVAL_SUCCESS_MSG:
    '<strong>Job Application removed!</strong><br><span style="font-size: small;">Your job application has been removed successfully.</span>',

  SAVED_JOB_BULKREMOVE_SUCCESS_MSG:
    '<strong>Job(s) Removed from Saved List</strong><br><span style="font-size: small;">The selected job(s) has been removed from your saved list</span>',

  SAVED_JOB_REMOVE_SUCCESS_MSG:
    '<strong>Job Removed from Saved List</strong><br><span style="font-size: small;">Job has been removed from your saved list</span>',

    JOB_ALERT_SAVED_SUCCESS_MSG: '<strong>Job Alert Saved Successfully</strong><br><span style="font-size: small;">Your job alert has been saved. You can track it in My Job Alerts.</span>',

  // Error Messages
  SUBMISSION_ERROR:
    'There was an error submitting the form. Please try again later.',
  FORM_INVALID:
    'Please fill out all required fields correctly before submitting.',
  FETCH_ERROR: 'Error fetching details',

  JOB_ALERT_SAVED_ERROR_MSG: '<strong>Error Saving Job Alert</strong><br><span style="font-size: small;">Your job alert could not be saved. Please try again.</span>',
  JOB_ALERT_TITLE_EXIST_MSG:'<strong>Job Alert Already Exists</strong><br><span style="font-size: small;">Please enter a different name for your job alert.</span>',
  JOB_ALERT_SAVE_SUCCESS_RESPONSE:'Job Alert Succesfull Created',
  JOB_ALERT_SAVE_DUPLICATE_RESPONSE:'Duplicate Job Alert Text',

  //REGEX
  ALPHABET_PATTERN: /^(?=[a-zA-Z])(?!.*\s\s)[a-zA-Z\s]+(?<!\s)$/,
  PH_PATTERN: /^\d{3}-\d{3}-\d{4}$/,
  EIN_PATTERN: /^\d{9}$/,
  URL_PATTERN:
    '^(https?://)?(www\\.)?([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}(/.*)?$',
    //ORGANIZATION_URLPATTERN: /^(https?:\/\/www\.|https?:\/\/|www\.)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?(:[0-9]{1,5})?(\/[^\s]*)?$/,
    EMAILPATTERN: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PHONEPATTERN: /^(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/,
  ZIPCODEPATTERN: /^\d{5}$/,
  ALPHAPATTERN: /^[A-Za-z\s]+$/,
  ADDRESSPATTERN: /^[A-Za-z0-9\s.,'-/()#&]+$/,
  ALPHA_NUMERIC_PATTERN: /^[a-zA-Z0-9]*$/,
  NUMERIC_PATTERN: /^[0-9]*$/,
  EEOPATTERN: /^[\s\S]*$/,
  JOBTITLEPATTERN: /^[A-Za-z0-9\s!@#$%^&*()_+={}\[\]:;"'<>,.?\/\\|`~-]+$/,
  //URLPATTERN: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,}|[a-zA-Z]{2,}))(:[0-9]{1,5})?(\/[^\s]*)?$/,
  WEBSITE_URL:/^(https?:\/\/(www\.)?|www\.)((localhost)|([a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?))(:[0-9]{1,5})?(\/[^\s]*)?$/,
  MAX_INT : 2147483647,

  JOBSEEKER: 'Job Seeker',
  EMPLOYER: 'Employer',
  EMPLOYERDATA: 'EmployerData',
  LAWASTAFF: 'LAWA Staff',
  COMMPARTNER: 'Community Partner',
  USER: 'user',
  LOGINSTATUS: 'loginStatus',
  TRUE: 'true',
  FALSE: 'false',

  //Routes
  JOBSEEKER_PROFILE_URL: '/profile/candidate/view',
  EMPLOYER_PROFILE_URL: '/profile/employer/view',
  EMPLOYER_EDIT_PROFILE: '/profile/employer/edit',
  JOBSEEKER_EDIT_PROFILE: '/profile/candidate/edit',
  JOBSEEKER_DASHBOARD: '/dashboard/jobseeker',
  EMPLOYER_DASHBOARD: '/dashboard/employer',
  HOME: '/',

  CBO_PROFILE_EDIT: 'cbo/profile/edit',
  CBO_PROFILE: 'cbo/profile',

  LAWASTAFF_PROFILE_EDIT: 'staff/profile/edit',
  LAWASTAFF_PROFILE: 'staff/profile',
  LAWASTAFF_DASHBAORD: 'staff/dashboard',

  //TABLE
  DEFAULTPAGESIZE: 10,
  DEFAULTPAGINDEX: 0,

  //STATUS
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  PENDING: 'Pending',

  //MAT_DIALOG
  MATVIEW: 'view',
  MATDISABLE: 'disable',

  //COMPENSATIONTYPES
  SALARY: 'Salary',
  HOURLY: 'Hourly',
  COMMISSION: 'Commission',
  PERHOUR:'/hour',
  PERYEAR:'/year',
  DOLLAR:'$',

  DEFAULT_USER_IMAGE: '/assets/icons/user-profile.svg',

  //JobStatus
  JOBSTATUS_PUBLISHED_ID: '36E98A2F-1B83-4A3B-9875-3EA7D460E943',
  JOBSTATUS_DRAFT_ID: '6C764811-3240-482C-BD2B-D93591D65B87',
  JOBSTAUTS_EXPIRED_ID: 'DAD74B0C-6889-4FB6-9C20-0E9096CD8DAD',
  JOBSTATUS_CLOSEJOB_ID: '83fd0694-4c78-4495-8b50-8fa645c72c67',

  //Job Application Status
  JOBAPPLICATIONSTATUS_REVIEW: '5D6E4BB5-A70C-4A64-AB9B-0E74D2A23870',
  JOBAPPLICATIONSTATUS_INTERVIEW: 'B214CB5C-BCE5-4498-A742-40D45402B3BD',
  JOBAPPLICATIONSTATUS_HIRED: 'BA657A95-D23A-4D6C-804B-963BC0DF589D',
  JOBAPPLICATIONSTATUS_NOT_HIRED: '9A460471-ED7F-4B6D-A82C-CB2AA1BB8A76',

  JOBSEEKER_ROLE_ID: "1A640B9D-D6B3-4618-9B27-5FD13C055232",
  EMPLOYER_ROLE_ID: "7A865A84-516B-4CAD-8E5B-6469BEED09F5",
  COMMUNITYPARTNER_ROLE_ID: "94B98AA3-4B2E-44A5-8810-22C17AB1EB91",
  LAWASTAFF_ROLE_ID: "9DE11E35-65E2-455B-B9CD-E7B763E75325",

  //Industry or Job Category
  CATEGORY_GOVERNMENT:'Government',
  CATEGORY_CONSTRUCTION:'Construction',
  CATEGORY_SERVICES:'Services',
  CATEGORY_CONCESSIONS:'Concessions',
  CATEGORY_MAINTENANCE:'Maintenance',
  CATEGORY_INFORMATIONTECHNOLOGY:'Information Technology',
  CATEGORY_AIRLINES:'Airlines',
  CATEGORY_OTHERAIRPORTEMPLOYER:'Other Airport Employer',
  CATEGORY_ENGINEERING:'Engineering',
  CATEGORY_RETAIL:'Retail',
  CATEGORY_DELIVERIES:'Deliveries',
  CATEGORY_TRANSPORTATION:'Transportation',
  CATEGORY_SECURITY:'Security',
  CATEGORY_FOODANDBEVERAGE:'Food and Beverage',

  //Reasons for contacting
  REASON_I_AM_HAVING_TROUBLE_LOGGIN_IN:"I am having trouble loggin in",
  REASON_I_NEED_TO_RESET_MY_PASSWORD:"I need to reset my password",
  REASON_I_HAVE_QUESTIONS_ABOUT_A_JOB_OR_AN_EMPLOYER_ON_THIS_WEBSITE:"I have questions about a job or an employer on this web site",
  REASON_I_NEED_ASSISTANCE_TO_HELP_ME_GET_OR_KEEP_A_JOB:"I need assistance to help me get or keep a job",
  REASON_I_NEED_ASSISTANCE_TO_IDENTIFY_AND_RECRUITE_MORE_POTENTIAL_EMPLOYEES:'I need assistance to identify and recruite more potential employees',
  REASON_OTHER:'Other',
  ORIGINAL_USER : 'OriginalUser',

  //Ein stored in local storage after Ein verified
  EIN_LOCAL:"Ein_Data"
};
