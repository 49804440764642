import { inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpContextToken,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, switchMap, tap, throwError } from 'rxjs';
import { MsalInterceptor } from '@azure/msal-angular';
import { TranslationService } from '../translation.service';
import { ConfigurationService } from '../configuration.service';

//@Injectable()
// export class AuthInterceptor implements HttpInterceptor {
//   constructor(private router: Router) {}

//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     // Retrieve the token from your authentication service or local storage
//     const authToken = localStorage.getItem('Access-Token-Azure-B2C'); // Replace with actual token retrieval logic

//     // Clone the request and add the Authorization header
//     const authReq = req.clone({
//       setHeaders: {
//         Authorization: `Bearer ${authToken}`
//       }
//     });

//     return next.handle(authReq).pipe(
//       catchError((error: HttpErrorResponse) => {
//         if (error.status === 403) {
//           this.router.navigate(['/unauthorized']);
//         }
//         return throwError(error);
//       })
//     );
//   }
// }
// export class AuthInterceptor implements HttpInterceptor {
//   constructor(private router: Router) {}

//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
//     return next.handle(req).pipe(
//       catchError((error: HttpErrorResponse) => {
//         if (error.status === 403) {
//           this.router.navigate(['/unauthorized']);
//         }
//         return throwError(error);
//       })
//     );
//   }
// }

export const BYPASS_MSAL = new HttpContextToken(() => false);

@Injectable()
export class AppHttpInterceptor
  extends MsalInterceptor
  implements HttpInterceptor
{
  override intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const translationService = inject(TranslationService);
    const configService = inject(ConfigurationService);
    if (request.context.get(BYPASS_MSAL)) {
      const localToken = localStorage.getItem('token');
      if (localToken) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${localToken}`,
          },
        });
      }

      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            return this.handleTokenRefresh(request, next, configService);
          }
          return throwError(() => error);
        }),
        tap((event) => {
          if (event instanceof HttpResponse) {
            const languageCode =
              sessionStorage.getItem('languageselected') || 'EN';
            setTimeout(() => {
              translationService.updateLanguage(languageCode);
            }, 500);
          }
        })
      );
    }

    return super.intercept(request, next).pipe(
      tap((event) => {
        if (
          event instanceof HttpResponse &&
          request.headers.get('apitype') !== 'translation'
        ) {
          const languageCode =
            sessionStorage.getItem('languageselected') || 'EN';
          setTimeout(() => {
            translationService.updateLanguage(languageCode);
          }, 500);
        }
      })
    );
  }

  private handleTokenRefresh(
    request: HttpRequest<any>,
    next: HttpHandler,
    settingsService: ConfigurationService
  ): Observable<HttpEvent<any>> {
    return settingsService.refreshAccessToken().pipe(
      switchMap((data: any) => {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${data.data.token}`,
          },
        });
        return next.handle(request);
      }),
      catchError((refreshError) => {
        return throwError(() => refreshError);
      })
    );
  }
}