import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { user } from '../model/user';
import { employer } from '../model/employer';
import { AppConstants } from '../appconstants';
import { BYPASS_MSAL } from './authguard/auth-interceptor';
import { UserProfileDto } from '../utils/types';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private apiUrl = environment.apiUrl;
  public signUpEvent: Subject<any> = new Subject();

  private profileUpdateSource = new ReplaySubject<any>(1);
  profilePicUpdated$ = this.profileUpdateSource.asObservable();

  private userNameSubject = new ReplaySubject<any>(1);
  userNameUpdated$ = this.userNameSubject.asObservable();


  public profileUpdated: Subject<any> = new Subject();

  source = [
    { id: 0, name: 'Word of Mouth' },
    { id: 1, name: 'Social Media (e.g., Facebook, Instagram, Twitter)' },
    { id: 2, name: 'Online Advertising (e.g., Google Ads, Facebook Ads)' },
    { id: 3, name: 'Search Engine (e.g., Google, Bing)' },
    { id: 4, name: 'Email Newsletter' },
    { id: 5, name: 'Website Referral (e.g., Blog, Partner Website)' },
    { id: 6, name: 'Traditional Media (e.g., TV, Radio, Print)' },
    { id: 7, name: 'Events or Conferences' },
    { id: 8, name: 'Direct Mail (e.g., Postcards, Brochures)' },
    { id: 9, name: 'Public Relations (e.g., Press Releases, News Articles)' },
    { id: 10, name: 'Community Board or Local Bulletin' },
    { id: 11, name: 'Partnership or Sponsorship' },
  ];

  jobSeekerDetails = {
    data: {
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com',
      zipCode: '90045',
      Phone: '(630) 155-6841',
      ageConfirmation: 'No',
      profilePicture: 'https://picsum.photos/200',
      PriorityIndustry: 1,
    },
  };

  constructor(private http: HttpClient) {}

  updateProfilePic(profile: any) {
    this.profileUpdateSource.next(profile);
  }

  updateUserName(userName: any) {
    this.userNameSubject.next(userName);
  }

  getUserProfileById(id: string): Observable<user> {
    return this.http.get<any>(`${this.apiUrl}/user/profile/id/${id}`);
  }

  saveUserProfile(formData: any): void {
    this.http
      .post<any>(`${this.apiUrl}/user`, formData)
      .subscribe((response: any) => {
        let user: any = localStorage?.getItem(AppConstants.USER);
        user = JSON.parse(user ?? '');
        user.userId = response.data;
        localStorage.setItem(AppConstants.USER, JSON.stringify(user));
      });
  }

  updateProfile(formData: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/user`, formData);
  }

  getreferralSource(): Observable<{ data: any[] }> {
    return this.http.get<any>(`${this.apiUrl}/user/lovs`);
  }
  ///////////////////////
  // get employer profile details
  getEmployerDetails(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/employer/A6BDB47A-4F5A-44BC-B7CC-2CC483FE67F6`
    );
  }

  // Save employer details
  saveEmployerDetails(employer: employer): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/employer`, employer);
  }

  getemployerLovs(): Observable<{ data: any[] }> {
    return this.http.get<{ data: any[] }>(`${this.apiUrl}/employer/lovs`);
  }

  getStateLovs(): Observable<{ data: any[] }> {
    return this.http.get<{ data: any[] }>(`${this.apiUrl}/user/state/lovs`);
  }
  //permanentDelete(id: string): Observable<any> {
  //  return this.http.delete<{ data: any[] }>(`${this.apiUrl}/employer/${id}`);
  //}

  permanentDelete(userId: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/user/${userId}`);
  }

  deleteAdditionalUser(userId: string) : Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/user/additionaluser/delete/${userId}`);
  }

  getUser(userId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/user/${userId}`);
  }

  enableDisableUser(userId: string): Observable<any> {
    return this.http.patch<any>(`${this.apiUrl}/user/${userId}`, {});
  }

  getUserClaims(): any {
    let user: any = localStorage?.getItem(AppConstants.USER);
    return JSON.parse(user);
  }

  saveEmployerProfile(formData: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/employer/Profile/Update`,
      formData
    );
  }

  ///saving additional user
  saveProfile(formData: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/user/profile/create/additionaluser`,
      JSON.stringify(formData),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  //updating additional user
  updateUserProfile(formData: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/user`, JSON.stringify(formData), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getAdditionalEmployerUsers(userId: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/user/additionalinfo/${userId}`);
  }

  filteredByName(employeeName: string): Observable<any> {
    return this.http.get<UserProfileDto[]>(
      `${this.apiUrl}/user/filteredByName?employeeName=${employeeName}`
    );
  }

  filteredEmployerByName(employerName: string): Observable<any> {
    return this.http.get<UserProfileDto[]>(
      `${this.apiUrl}/Employer/filteredByName?employerName=${employerName}`
    );
  }

  filteredCPByName(cpName: string): Observable<any> {
    return this.http.get<UserProfileDto[]>(
      `${this.apiUrl}/cbo/filteredByName?cpName=${cpName}`
    );
  }

  updateLocalStorage(key: string, updateKey: string, updateValue: any): void {
    const jsonString = localStorage.getItem(key);
    let jsonObject = jsonString ? JSON.parse(jsonString) : {};
    jsonObject[updateKey] = updateValue;
    const updatedJsonString = JSON.stringify(jsonObject);
    localStorage.setItem(key, updatedJsonString);
  }

  // get cbo profile details
  getCBODetails(obj: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/CBO/${obj.userId}`);
  }

  getCBOLovs(): Observable<{ data: any[] }> {
    return this.http.get<{ data: any[] }>(`${this.apiUrl}/CBO/lov`);
  }
  verifyEin(data: any) {
        return this.http.get(
      `${this.apiUrl}/employer/checkein/${data.einNumber}/${data.companyName}/validate`,
      { context: new HttpContext().set(BYPASS_MSAL, true) }
    );
  }
  saveEinLocal(data: any) {
    localStorage.setItem(AppConstants.EIN_LOCAL, JSON.stringify(data));
  }
  getEinData() {
    return JSON.parse(localStorage.getItem(AppConstants.EIN_LOCAL) ?? '{}');
  }
  clearEinData() {
    localStorage.removeItem(AppConstants.EIN_LOCAL);
  }
}
